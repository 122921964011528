<mat-nav-list>
    <a mat-list-item routerLink="/" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon>
        <span class="nav-caption">Home</span>
    </a>

    <a mat-list-item routerLink="/buy" (click)="onSidenavClose()">
        <span class="nav-caption">buy</span>
    </a>

    <a mat-list-item routerLink="/profit-loss" (click)="onSidenavClose()">
        <span class="nav-caption">profit-loss</span>
    </a>

    <a mat-list-item routerLink="/break-even-sell" (click)="onSidenavClose()">
        <span class="nav-caption">break-even-sell</span>
    </a>

    <a mat-list-item routerLink="/profile" (click)="onSidenavClose()">
        <span class="nav-caption">Profile</span>
    </a>
</mat-nav-list>