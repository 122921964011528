<!-- <div class="card-holder text-center">
    <div class="card">
        <div class="card-header">
            breakeven sell calc for <span style="color: green;"> {{ticker}} </span>
        </div>

        <div class="card-body">
            <form [formGroup]="breakevenDialogformGroup" (change)="handleChange()">
                <div class="row">

                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>bought price per share</mat-label>
                            <input 
                            matInput required 
                            formControlName="cAvg" 
                            type="number">
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <mat-label>current number of stocks</mat-label>
                            <input 
                            matInput required 
                            formControlName="cNStocks" 
                            type="number">
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <mat-label>number of stock to keep</mat-label>
                            <input 
                            matInput required 
                            formControlName="sharesToKeep" 
                            type="number">
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <br><br>
            <div class="adjusted display-result">
                <div class="row final-display">
                    <div class="col-5 final-display-text">total investment</div>
                    <div class="col final-display-number">${{this.totalSpent}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">shares to sell</div>
                    <div class="col final-display-number">{{this.sharesToSell}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">avg. sell price</div>
                    <div class="col final-display-number">${{this.sellPrice}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">total equity to sell</div>
                    <div class="col final-display-number">${{this.totalEquityToSell}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">remaining shares worth at sell price</div>
                    <div class="col final-display-number">${{this.remainingSharesWorthAtSellPrice}} </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<br>

<button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
</button> -->

<h2 mat-dialog-title align="center" style="background-color: antiquewhite;">
    Breakeven sell calc for <span style="color: green;"> {{ticker}} </span>
</h2>

<mat-dialog-content>
    <form [formGroup]="breakevenDialogformGroup" (change)="handleChange()">
        <div class="row">

            <div class="col-6">
                <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                    <span matPrefix>$ &nbsp;</span>
                    <mat-label>price per share</mat-label>
                    <input 
                    matInput required 
                    formControlName="cAvg" 
                    type="number">
                </mat-form-field>
            </div>

            <div class="col-6">
                <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                    <mat-label>number of stocks</mat-label>
                    <input 
                    matInput required 
                    formControlName="cNStocks" 
                    type="number">
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                    <mat-label>number of stock to keep</mat-label>
                    <input 
                    matInput required 
                    formControlName="sharesToKeep" 
                    type="number">
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="adjusted display-result">
        <div class="row final-display">
            <div class="col-5 final-display-text">total investment</div>
            <div class="col final-display-number">${{this.totalSpent}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">shares to sell</div>
            <div class="col final-display-number">{{this.sharesToSell}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">avg. sell price</div>
            <div class="col final-display-number">${{this.sellPrice}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">total equity to sell</div>
            <div class="col final-display-number">${{this.totalEquityToSell}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">remaining shares worth at sell price</div>
            <div class="col final-display-number">${{this.remainingSharesWorthAtSellPrice}} </div>
        </div>
    </div>

</mat-dialog-content>


<mat-dialog-actions align="center">
    <button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
    </button>
</mat-dialog-actions>





