import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';



@Component({
  selector: 'app-risk-calc-dialog',
  templateUrl: './risk-calc-dialog.component.html',
  styleUrls: ['./risk-calc-dialog.component.css']
})
export class RiskCalcDialogComponent implements OnInit {

  riskCalcFormDialog : FormGroup;
  equityToBeSold : number = 0;
  avgPricePerShareTobeSold : number = 0;
  investment : number = 0;
  loss : number = 0;

  ticker : string;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data : Ticker,
    private trading : TradingHelperService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.ticker = this.data.ticker;
    this.riskCalcFormDialog = this.formBuilder.group({
      overallPortfolio : ['',[Validators.required, Validators.min(0)]],
      nShares : [{value: this.data.qty, disabled: true}],
      avgPricePerShare : [{value: this.data.price, disabled: true}],
      riskTolerancePct : ['',[Validators.required, Validators.min(0)]]
    })
  }

  handleChange(){
    const data = this.trading.riskCalculator(
      this.overallPortfolio.value,
      this.nShares.value,
      this.avgPricePerShare.value,
      this.riskTolerancePct.value
    );

    this.equityToBeSold = data.equityToBeSold >= 0 ? data.equityToBeSold : 0;
    this.avgPricePerShareTobeSold = data.avgPricePerShareTobeSold >= 0 ? data.avgPricePerShareTobeSold : 0;

    if (this.equityToBeSold >=0 && this.avgPricePerShareTobeSold >=0){
      this.investment = this.nShares.value * this.avgPricePerShare.value;
      this.loss = this.investment - this.equityToBeSold;
    }
  }

  //getters
  get overallPortfolio(){return this.riskCalcFormDialog.get('overallPortfolio')}
  get nShares(){return this.riskCalcFormDialog.get('nShares')}
  get avgPricePerShare(){return this.riskCalcFormDialog.get('avgPricePerShare')}
  get riskTolerancePct(){return this.riskCalcFormDialog.get('riskTolerancePct')}

}
