import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessengerService } from 'src/app/services/messenger/messenger.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    public auth : AuthService,
    private messenger : MessengerService
  ) { }

  ngOnInit(): void {
  }

  handleSignIn(){
    this.auth.googleSignIn()
      .then(()=>{
        this.messenger.sendMsg({
          msg: 'User added!',
          type: 'success'
        })
      },
      () => {
        this.messenger.sendMsg({
          msg: 'User could not be added!',
          type: 'danger'
        })
      }
      )
  }

}
