import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';
import { TickerService } from 'src/app/services/ticker/ticker.service';

import { MatDialog } from '@angular/material/dialog';
import { DeleteTickerDialogComponent } from '../delete-ticker-dialog/delete-ticker-dialog.component';


@Component({
  selector: 'app-update-ticker-dialog',
  templateUrl: './update-ticker-dialog.component.html',
  styleUrls: ['./update-ticker-dialog.component.css']
})
export class UpdateTickerDialogComponent implements OnInit {

  updateTicker : FormGroup;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data : Ticker,
    private tickerService : TickerService,
    private formBuilder : FormBuilder,
    public dialog : MatDialog
    ) { }

  ngOnInit(): void {
    this.updateTicker = this.formBuilder.group({
      ticker : [this.data.ticker,[Validators.required]],
      qty : [this.data.qty,[Validators.required, Validators.min(0)]],
      avgPrice : [this.data.price,[Validators.required, Validators.min(0)]],
    })
  }


  handleUpdateTicker()
  {

    const data : Ticker = {
      tickerID : this.data.tickerID,
      ticker : this.ticker.value,
      index : this.ticker.value,
      qty : this.qty.value,
      price : this.avgPrice.value
    }

    this.tickerService.setTicker(data, true);
  }

  openDeleteDialog(){
    this.dialog.open(DeleteTickerDialogComponent, {data : {
      ticker : this.data.ticker,
      tickerID : this.data.tickerID
    }})
  }


  // getters
  get ticker(){return this.updateTicker.get('ticker')}
  get qty(){return this.updateTicker.get('qty')}
  get avgPrice(){return this.updateTicker.get('avgPrice')}

}
