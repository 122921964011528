<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="profile menu">
    <!-- profile -->
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #menu="matMenu">

    <button mat-menu-item routerLink="/tickers">
        <mat-icon>timeline</mat-icon>
        <span>Saved Tickers</span>
    </button>

    <button mat-menu-item routerLink="/profile">
        <mat-icon>build</mat-icon>
        <span>Account Settings</span>
    </button>

    <button mat-menu-item>
        <mat-icon>login</mat-icon>

        <span *ngIf="auth.user$ | async as user; else login">
            <a (click)="auth.signOut()" style="color: black;">sign out</a>
        </span>

        <ng-template #login>
            <a (click)="auth.googleSignIn()" style="color: black;">sign in with Google</a>
        </ng-template>

    </button>
</mat-menu>