import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ticker } from 'src/app/models/ticker.model';
import { TickerService } from 'src/app/services/ticker/ticker.service';

@Component({
  selector: 'app-add-ticker-dialog',
  templateUrl: './add-ticker-dialog.component.html',
  styleUrls: ['./add-ticker-dialog.component.css']
})
export class AddTickerDialogComponent implements OnInit {

  addTickerForm : FormGroup;

  constructor(
    private tickerService : TickerService,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.addTickerForm = this.formBuilder.group({
      ticker : ['',[Validators.required, Validators.minLength(1)]],
      qty : ['',[Validators.required, Validators.min(0), Validators.minLength(1)]],
      avgPrice : ['',[Validators.required, Validators.min(0), Validators.minLength(1)]],
    })
  }

  handleSaveTicker()
  {

    const data : Ticker = {
      ticker : this.ticker.value,
      index : this.ticker.value,
      qty : this.qty.value,
      price : this.avgPrice.value
    }

    this.tickerService.setTicker(data, false);
  }


  // getters
  get ticker(){return this.addTickerForm.get('ticker')}
  get qty(){return this.addTickerForm.get('qty')}
  get avgPrice(){return this.addTickerForm.get('avgPrice')}


}
