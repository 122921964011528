import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  helloImgLink : string = "";
  
  // hellos
  cuteAttack : string = "https://media.tenor.co/images/3ccff8c4b2443d93811eac9b2fd56f11/raw";
  adele : string = "https://media.tenor.co/images/30c65a2c3a5b45f1569e2d09ebfe498b/raw";
  goofy : string = "https://media.tenor.co/images/5e362c1d94d9dba23814ac7ba5944161/tenor.gif";
  chipmunk : string = "https://i.pinimg.com/originals/a1/9f/09/a19f09386c8bf4a5ba48d07a7b4171f2.gif";
  stonks : string = "https://media.giphy.com/media/XDAY1NNG2VvobAp9o0/giphy.gif";
  itwentupagain : string = "https://media.giphy.com/media/1AjFk7MzJBT2UPYKLB/giphy.gif";
  sellsellsell : string = "https://media.giphy.com/media/l0HlDDyxBfSaPpU88/giphy.gif";


  // hello1 = "https://cdn.dribbble.com/users/1850911/screenshots/3725676/hello.gif";
  // hello2 = "https://www.newfangled.com/wp-content/uploads/2014/10/hello.gif";
  // ohilovethatstock : string = "https://media.giphy.com/media/AgHBbekqDik0g/giphy.gif";


  constructor(public auth : AuthService) { }

  ngOnInit(): void {
    const random = Math.floor(Math.random() * (7 - 1) + 1);
    
    if(random == 1)
      this.helloImgLink = this.sellsellsell;
    else if(random==2)
      this.helloImgLink = this.adele;
    else if(random==3)
      this.helloImgLink = this.itwentupagain;
    else if(random==4)
      this.helloImgLink = this.goofy;
    else if(random==5)
      this.helloImgLink = this.cuteAttack;
    else if(random==5)
      this.helloImgLink = this.stonks;
    else
      this.helloImgLink = this.stonks;



  }

  getRandomPic(){
    console.log("hello");
    
  }

}
