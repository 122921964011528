<!-- <div class="card-holder text-center">
    <div class="card">
        <div class="card-header">
            Risk Tolerance Calc for <span style="color: green;"> {{ticker}} </span>
        </div>

        <div class="card-body">
            <form [formGroup]="riskCalcFormDialog" (change)="handleChange()">

                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>total portfoltio</mat-label>
                            <input 
                            matInput required 
                            formControlName="overallPortfolio" 
                            type="number">
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>% &nbsp;</span>
                            <mat-label>risk tolerance percentage [0,100]</mat-label>
                            <input 
                            matInput required 
                            formControlName="riskTolerancePct" 
                            type="number">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="col">
                            <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                                <span matPrefix>$ &nbsp;</span>
                                <mat-label>current avg. price per share</mat-label>
                                <input 
                                matInput required 
                                formControlName="avgPricePerShare" 
                                type="number">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col">
                        <div class="col">
                            <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                                
                                <mat-label>number of shares</mat-label>
                                <input 
                                matInput required 
                                formControlName="nShares" 
                                type="number">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </form>

            <br><br>
            <div class="adjusted display-result">
                <div class="row final-display">
                    <div class="col-5 final-display-text">avg. price per share</div>
                    <div class="col final-display-number">${{this.avgPricePerShareTobeSold}} </div>
                </div>
                <div class="row final-display">
                    <div class="col-5 final-display-text">equity to sell</div>
                    <div class="col final-display-number">${{this.equityToBeSold}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">investment in this share</div>
                    <div class="col final-display-number">${{this.investment}} </div>
                </div>

                <div class="row final-display">
                    <div class="col-5 final-display-text">loss</div>
                    <div class="col final-display-number">${{this.loss}} </div>
                </div>
                
            </div>


            
        </div>
    </div>
</div>

<br>
<button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
</button> -->

<h2 mat-dialog-title align="center" style="background-color: antiquewhite;">
    Risk calc for <span style="color: green;"> {{ticker}} </span>
</h2>


<mat-dialog-content>
    <form [formGroup]="riskCalcFormDialog" (change)="handleChange()">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                    <span matPrefix>$ &nbsp;</span>
                    <mat-label>total portfoltio</mat-label>
                    <input 
                    matInput required 
                    formControlName="overallPortfolio" 
                    type="number">
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                    <span matPrefix>% &nbsp;</span>
                    <mat-label>risk tolerance percentage [0,100]</mat-label>
                    <input 
                    matInput required 
                    formControlName="riskTolerancePct" 
                    type="number">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>avg. price per share</mat-label>
                        <input 
                        matInput required 
                        formControlName="avgPricePerShare" 
                        type="number">
                    </mat-form-field>
            </div>

            <div class="col-6">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        
                        <mat-label>number of shares</mat-label>
                        <input 
                        matInput required 
                        formControlName="nShares" 
                        type="number">
                    </mat-form-field>
            </div>
        </div>

    </form>


    <br>
    <div class="adjusted display-result">
        <div class="row final-display">
            <div class="col-5 final-display-text">avg. price per share</div>
            <div class="col final-display-number">${{this.avgPricePerShareTobeSold}} </div>
        </div>
        <div class="row final-display">
            <div class="col-5 final-display-text">equity to sell</div>
            <div class="col final-display-number">${{this.equityToBeSold}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">investment in this share</div>
            <div class="col final-display-number">${{this.investment}} </div>
        </div>

        <div class="row final-display">
            <div class="col-5 final-display-text">loss</div>
            <div class="col final-display-number">${{this.loss}} </div>
        </div>
        
    </div>


</mat-dialog-content>


<mat-dialog-actions align="center">
    <button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
    </button>

</mat-dialog-actions>
