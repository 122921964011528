import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';
import { MatDialog } from '@angular/material/dialog';
import { BuyDialogComponent } from '../calc-options/buy-dialog/buy-dialog.component';
import { ProfitLossDialogComponent } from '../calc-options/profit-loss-dialog/profit-loss-dialog.component';
import { BreakevenSellDialogComponent } from '../calc-options/breakeven-sell-dialog/breakeven-sell-dialog.component';
import { RiskCalcDialogComponent } from '../calc-options/risk-calc-dialog/risk-calc-dialog.component';


@Component({
  selector: 'app-calc-options-dialog',
  templateUrl: './calc-options-dialog.component.html',
  styleUrls: ['./calc-options-dialog.component.css']
})
export class CalcOptionsDialogComponent implements OnInit {

  ticker : string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data : Ticker,
    public dialog : MatDialog
  ) { }

  ngOnInit(): void {
    this.ticker = this.data.ticker;
  }


  openBuyCalcDialog(){
    this.dialog.open(BuyDialogComponent, {data : {
      ticker : this.data.ticker,
      price : this.data.price,
      qty : this.data.qty
    }});
  }


  openProfitLossDialog(){
    this.dialog.open(ProfitLossDialogComponent, {data : {
      ticker : this.data.ticker,
      price : this.data.price,
      qty : this.data.qty
    }});
  }

  openBreakevenSellDialog(){
    this.dialog.open(BreakevenSellDialogComponent, {data : {
      ticker : this.data.ticker,
      price : this.data.price,
      qty : this.data.qty
    }});
  }

  openRiskCalcDialog(){
    this.dialog.open(RiskCalcDialogComponent, {data : {
      ticker : this.data.ticker,
      price : this.data.price,
      qty : this.data.qty
    }});
  }

}
