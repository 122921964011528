import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { 
  FormsModule,
  ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutingModule } from './modules/routing/routing.module';
import { HomeComponent } from './components/home/home.component';
import { MaterialModule } from './modules/material/material.module';
import { SidenavListComponent } from './components/shared/sidenav-list/sidenav-list.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { BuyComponent } from './components/calc/buy/buy.component';
import { ProfitLossComponent } from './components/calc/profit-loss/profit-loss.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { from } from 'rxjs';
import { AccountSettingsComponent } from './components/account/account-settings/account-settings.component';
import { TickersComponent } from './components/account/tickers/tickers.component';
import { HeaderProfileMenuComponent } from './components/shared/header-profile-menu/header-profile-menu.component';
import { BreakEvenSellComponent } from './components/calc/break-even-sell/break-even-sell.component';
import { ActiveServiceLinksComponent } from './components/shared/active-service-links/active-service-links.component';
import { RiskCalcComponent } from './components/calc/risk-calc/risk-calc.component';
import { AlertComponent } from './components/shared/alert/alert.component';
import { AddTickerDialogComponent } from './components/dialogs/add-ticker-dialog/add-ticker-dialog.component';
import { UpdateTickerDialogComponent } from './components/dialogs/update-ticker-dialog/update-ticker-dialog.component';
import { DeleteTickerDialogComponent } from './components/dialogs/delete-ticker-dialog/delete-ticker-dialog.component';
import { SearchPipe } from './pipes/search/search.pipe';
import { CalcOptionsDialogComponent } from './components/dialogs/calc-options-dialog/calc-options-dialog.component';
import { BuyDialogComponent } from './components/dialogs/calc-options/buy-dialog/buy-dialog.component';
import { ProfitLossDialogComponent } from './components/dialogs/calc-options/profit-loss-dialog/profit-loss-dialog.component';
import { BreakevenSellDialogComponent } from './components/dialogs/calc-options/breakeven-sell-dialog/breakeven-sell-dialog.component';
import { RiskCalcDialogComponent } from './components/dialogs/calc-options/risk-calc-dialog/risk-calc-dialog.component';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavListComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    BuyComponent,
    ProfitLossComponent,
    AccountSettingsComponent,
    TickersComponent,
    HeaderProfileMenuComponent,
    BreakEvenSellComponent,
    ActiveServiceLinksComponent,
    RiskCalcComponent,
    AlertComponent,
    AddTickerDialogComponent,
    UpdateTickerDialogComponent,
    DeleteTickerDialogComponent,
    SearchPipe,
    CalcOptionsDialogComponent,
    BuyDialogComponent,
    ProfitLossDialogComponent,
    BreakevenSellDialogComponent,
    RiskCalcDialogComponent,
  ],
  entryComponents : [
    AddTickerDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
