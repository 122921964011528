<h2 mat-dialog-title>
    add new ticker
</h2>

<mat-dialog-content>

    <form [formGroup]="addTickerForm">

        <mat-form-field appearance="outline"  class="input-field">
            <!-- <span matPrefix>$ &nbsp;</span> -->
            <mat-label>ticker symbol</mat-label>
            <input 
            matInput required 
            formControlName="ticker" 
            type="text">
        </mat-form-field>

        <mat-form-field appearance="outline"  class="input-field">
            <span matPrefix>$ &nbsp;</span>
            <mat-label>avg. cost per share</mat-label>
            <input 
            matInput required 
            formControlName="avgPrice" 
            type="number">
        </mat-form-field>


        <mat-form-field appearance="outline"  class="input-field">
            <!-- <span matPrefix>$ &nbsp;</span> -->
            <mat-label>number of shares</mat-label>
            <input 
            matInput required 
            formControlName="qty" 
            type="number">
        </mat-form-field>
    </form>


</mat-dialog-content>


<mat-dialog-actions>

    <div class="row">
        <div class="col-6 float-start">
            <button mat-button mat-dialog-close> cancel
                <mat-icon>close</mat-icon>
            </button>
        </div>


        <div class="col-6 float-end">
            <button mat-button mat-dialog-close (click)="handleSaveTicker()" [disabled]="addTickerForm.invalid" class="btn-success"> add
                <mat-icon>add</mat-icon>
            </button>
        </div>
        

    </div>


    
    
</mat-dialog-actions>