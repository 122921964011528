<div id="helloImg">
    <img [src]=helloImgLink alt="" style="max-width: 100%;">
</div>
<br>

<div *ngIf="auth.user$ | async as user; else default">
    Hi, {{user.displayName}}!
</div>

<ng-template #default>
    Hi, there!
</ng-template>


<br><br>

This website is still in the beta phase. There might be a few (actually a lot) bugs 
<img src="https://1.bp.blogspot.com/-a9TW1QAI8oA/VufwIM_WbbI/AAAAAAAACOQ/k2fmvig2wNUsc5af9PFtOvbpwZJVQsOZQ/s1600/green-bug-image.png" alt="" style="width: 1rem;">
here and there. Drop me an 
<a href="mailto:farhanhyder@aol.com?subject=Hello Awesomeness">email</a>
if you find any. Any suggestions/requests are much appreciated.
<br><br>
Happy trading :)    


<app-active-service-links></app-active-service-links>
