import { Component, OnInit } from '@angular/core';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {

  newBuyForm : FormGroup;
  currTotalEquity : number = 0;
  newTotalEquity : number = 0;
  adjustedTotalStocks : number = 0;
  adjustedTotalInvestment : number = 0;
  adjustedAvgPrice : number = 0;

  constructor(
    private trading : TradingHelperService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.newBuyForm = this.formBuilder.group({
      cpps : ['',[Validators.required, Validators.min(0)]],
      cnos : ['',[Validators.required, Validators.min(0)]],
      npps : ['',[Validators.required, Validators.min(0)]],
      nnos : ['',[Validators.required, Validators.min(0)]],
    })
  }

  handleChange(){
    
    const data = this.trading.newStockPricingCalc(
      this.currPricePerShare.value,
      this.currNumOfShares.value,
      this.newPricePerShare.value,
      this.newNumOfShares.value);

    this.currTotalEquity = data.currTotalEquity;
    this.newTotalEquity = data.newTotal;
    this.adjustedTotalStocks = data.adjustedTotalStocks;
    this.adjustedTotalInvestment = data.adjustedTotalInvestment;
    this.adjustedAvgPrice = data.adjustedAvgPrice;
  }

  //getters
  get currPricePerShare(){return this.newBuyForm.get('cpps')}
  get currNumOfShares(){return this.newBuyForm.get('cnos')}
  get newPricePerShare(){return this.newBuyForm.get('npps')}
  get newNumOfShares(){return this.newBuyForm.get('nnos')}

}
