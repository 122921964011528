<app-alert></app-alert>

<div *ngIf="auth.user$ | async as user; else login">

    <div class="card text-center">
        <img src={{user.photoURL}} class="display-image" alt="...">
        <div class="card-body">
          <h2 class="card-title">{{user.displayName}}</h2>
          <p class="card-text">email: {{user.email}}</p>
          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>

        <small>your profile details are obtained from your google account</small>
    </div>


</div>


<ng-template #login>
    <button class="button" (click)="auth.googleSignIn()">
        login with google
    </button>
</ng-template>