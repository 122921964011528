<div class="text-center" style="background-color:steelblue;">
    <h3 mat-dialog-title>
        update <strong>{{ticker.value}}</strong>
    </h3>
    
</div>


<mat-dialog-content>

    <form [formGroup]="updateTicker">

        <mat-form-field appearance="outline"  class="input-field">
            <!-- <span matPrefix>$ &nbsp;</span> -->
            <mat-label>ticker symbol</mat-label>
            <input 
            matInput required 
            formControlName="ticker" 
            type="text">
        </mat-form-field>

        <mat-form-field appearance="outline"  class="input-field">
            <span matPrefix>$ &nbsp;</span>
            <mat-label>avg. cost per share</mat-label>
            <input 
            matInput required 
            formControlName="avgPrice" 
            type="number">
        </mat-form-field>


        <mat-form-field appearance="outline"  class="input-field">
            <!-- <span matPrefix>$ &nbsp;</span> -->
            <mat-label>number of shares</mat-label>
            <input 
            matInput required 
            formControlName="qty" 
            type="number">
        </mat-form-field>
    </form>


</mat-dialog-content>


<mat-dialog-actions>

    <div class="row">
        <div class="col-4 float-start">
            <button mat-button mat-dialog-close> cancel </button>
        </div>

        <div class="col-4">
            <button mat-button mat-dialog-close (click)="openDeleteDialog()"> delete
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <div class="col-4 float-end">
            <button mat-button mat-dialog-close (click)="handleUpdateTicker()"
             [disabled]="updateTicker.invalid" class="btn-success"> 
             update </button>
        </div>

    </div>


    

</mat-dialog-actions>