import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';

@Component({
  selector: 'app-risk-calc',
  templateUrl: './risk-calc.component.html',
  styleUrls: ['./risk-calc.component.css']
})
export class RiskCalcComponent implements OnInit {

  riskCalcForm : FormGroup;
  equityToBeSold : number = 0;
  avgPricePerShareTobeSold : number = 0;
  investment : number = 0;
  loss : number = 0;


  constructor(
    private trading : TradingHelperService,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.riskCalcForm = this.formBuilder.group({
      overallPortfolio : ['',[Validators.required, Validators.min(0)]],
      nShares : ['',[Validators.required, Validators.min(0)]],
      avgPricePerShare : ['',[Validators.required, Validators.min(0)]],
      riskTolerancePct : ['',[Validators.required, Validators.min(0)]]
    })
  }


  handleChange(){
    const data = this.trading.riskCalculator(
      this.overallPortfolio.value,
      this.nShares.value,
      this.avgPricePerShare.value,
      this.riskTolerancePct.value
    );

    this.equityToBeSold = data.equityToBeSold >= 0 ? data.equityToBeSold : 0;
    this.avgPricePerShareTobeSold = data.avgPricePerShareTobeSold >= 0 ? data.avgPricePerShareTobeSold : 0;

    if (this.equityToBeSold >=0 && this.avgPricePerShareTobeSold >=0){
      this.investment = this.nShares.value * this.avgPricePerShare.value;
      this.loss = this.investment - this.equityToBeSold;
    }
  }

  //getters
  get overallPortfolio(){return this.riskCalcForm.get('overallPortfolio')}
  get nShares(){return this.riskCalcForm.get('nShares')}
  get avgPricePerShare(){return this.riskCalcForm.get('avgPricePerShare')}
  get riskTolerancePct(){return this.riskCalcForm.get('riskTolerancePct')}

}
