import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Ticker } from 'src/app/models/ticker.model';
import { AuthService } from '../auth/auth.service';
import { count, tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class TickerService {


  constructor(
    private fs : AngularFirestore,
    private auth : AuthService) { }

  private increaseLocalStorageCounter(crudName){
    var counter = parseInt(localStorage.getItem(crudName)) + 1;
    localStorage.setItem(crudName, counter.toString());

    console.log(crudName + " " + localStorage.getItem(crudName));
  }

  getTickers(uid:string) : Observable<Ticker[]>
  {
    // TODO:
    // this.increaseLocalStorageCounter("read");
    
    const collectionName : string = "users/"+uid+"/tickers/";
    const productsCollection  : AngularFirestoreCollection<Ticker> = this.fs.collection(collectionName);
    return productsCollection.valueChanges();
  }

  private createNewID() : string{
    return this.fs.createId();
  }

  setTicker(ticker : Ticker, update: boolean = true){

    // TODO:
    // this.increaseLocalStorageCounter("write");

    if(update == false){
      ticker.tickerID = this.createNewID();
    }
    
    this.auth.isLoggedIn().pipe(
      tap(user => {
        if(user)
        {
          const collectionName : string = `users/${user.uid}/tickers/`;
          return this.fs.doc<Ticker>(collectionName+ticker.tickerID).set(ticker, {merge: true});
        } else{
          console.log("meow");
        }
      })
    ).subscribe()
  }

  deleteTicker(tickerID : string){

    // TODO: 
    // this.increaseLocalStorageCounter("delete");

    this.auth.isLoggedIn().pipe(
      tap(user => {
        if(user)
        {
          const collectionName : string = `users/${user.uid}/tickers/`;
          return this.fs.doc<Ticker>(collectionName+tickerID).delete();
        } else{
          console.log("meow");
        }
      })
    ).subscribe()


  }


}
