<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand" routerLink="/">TradingCalc</a>
     
      <div class="collapse navbar-collapse" id="navbarNav">

        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/buy" [routerLinkActive]="['active']">buy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/profit-loss" [routerLinkActive]="['active']">profit/loss</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/break-even-sell" [routerLinkActive]="['active']">breakeven sell</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/risk-calc" [routerLinkActive]="['active']">risk tolerance</a>
          </li>
        </ul>


      </div>

      <div fxFlex fxLayout fxLayoutAlign="end">
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <app-header-profile-menu></app-header-profile-menu>
            </li>
        </ul>
    </div>
    </div>
  </nav>