import { Component, OnInit, Inject } from '@angular/core';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';



@Component({
  selector: 'app-breakeven-sell-dialog',
  templateUrl: './breakeven-sell-dialog.component.html',
  styleUrls: ['./breakeven-sell-dialog.component.css']
})
export class BreakevenSellDialogComponent implements OnInit {


  breakevenDialogformGroup : FormGroup;
  totalSpent : number = 0;
  sharesToSell : number = 0;
  sellPrice : number = 0;
  totalEquityToSell : number = 0;
  remainingSharesWorthAtSellPrice : number = 0;

  ticker : string;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data : Ticker,
    private formBuilder: FormBuilder,
    private trading : TradingHelperService
  ) { }

  ngOnInit(): void {
    this.ticker = this.data.ticker;
    this.breakevenDialogformGroup = this.formBuilder.group({
      cAvg : [{value: this.data.price, disabled : true}],
      cNStocks : [{value: this.data.qty, disabled : true}],
      sharesToKeep : ['',[Validators.required, Validators.min(0)]],
    })
  }

  handleChange(){
    const data = this.trading.breakEvenSell(
      this.cAvg.value,
      this.cNStocks.value,
      this.sharesToKeep.value
    );

    this.totalSpent = data.totalSpent;
    this.sharesToSell = data.sharesToSell;
    this.sellPrice = data.sellPrice;
    this.totalEquityToSell = data.totalEquityToSell;
    this.remainingSharesWorthAtSellPrice = data.remainingSharesWorthAtSellPrice;
  }

  //getters
  get cAvg(){return this.breakevenDialogformGroup.get('cAvg')}
  get cNStocks(){return this.breakevenDialogformGroup.get('cNStocks')}
  get sharesToKeep(){return this.breakevenDialogformGroup.get('sharesToKeep')}

}
