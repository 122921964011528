import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-service-links',
  templateUrl: './active-service-links.component.html',
  styleUrls: ['./active-service-links.component.css']
})
export class ActiveServiceLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
