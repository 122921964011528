import { Component, OnInit } from '@angular/core';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-break-even-sell',
  templateUrl: './break-even-sell.component.html',
  styleUrls: ['./break-even-sell.component.css']
})
export class BreakEvenSellComponent implements OnInit {

  formGroup : FormGroup;
  totalSpent : number = 0;
  sharesToSell : number = 0;
  sellPrice : number = 0;
  totalEquityToSell : number = 0;
  remainingSharesWorthAtSellPrice : number = 0;

  constructor(
    private trading : TradingHelperService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      cAvg : ['',[Validators.required, Validators.min(0)]],
      cNStocks : ['',[Validators.required, Validators.min(0)]],
      sharesToKeep : ['',[Validators.required, Validators.min(0)]],
    })
  }

  handleChange(){
    const data = this.trading.breakEvenSell(
      this.cAvg.value,
      this.cNStocks.value,
      this.sharesToKeep.value
    );

    this.totalSpent = data.totalSpent;
    this.sharesToSell = data.sharesToSell;
    this.sellPrice = data.sellPrice;
    this.totalEquityToSell = data.totalEquityToSell;
    this.remainingSharesWorthAtSellPrice = data.remainingSharesWorthAtSellPrice;
  }

  //getters
  get cAvg(){return this.formGroup.get('cAvg')}
  get cNStocks(){return this.formGroup.get('cNStocks')}
  get sharesToKeep(){return this.formGroup.get('sharesToKeep')}

}
