<!-- <div class="card-holder text-center dialogBox">
    

    <div class="card">
        <div class="card-header">
          Buy calc for <span style="color: green;"> {{ticker}} </span>
        </div>
        <div class="card-body">
    
            <form [formGroup]="newBuyDialogForm" (change)="handleChange()">
    
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>price per share</mat-label>
                            <input 
                            matInput required 
                            formControlName="cpps" 
                            type="number"
                            disabled>
                        </mat-form-field>
                    </div>
    
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <mat-label>number of shares</mat-label>
                            <input 
                            matInput required 
                            formControlName="cnos" 
                            type="number">
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="row display-result">
                    <div class="col">
    
                        <div class="col">
                            <span class="display-result-text">
                                current total investment
                            </span>
                        </div>
                        <div class="col">
                            <span class="display-result-number">
                                ${{this.currTotalEquity}}
                            </span>
                        </div>
                        
                        
                    </div>
                </div>
    
                <br><br>
    
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>new price per share</mat-label>
                            <input 
                            matInput required 
                            formControlName="npps" 
                            type="number">
                        </mat-form-field>
                    </div>
    
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <mat-label>new number of shares</mat-label>
                            <input 
                            matInput required 
                            formControlName="nnos" 
                            type="number">
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="row display-result">
                    <div class="col">
    
                        <div class="col"><span class="display-result-text">new total investment</span></div>
                        <div class="col"><span class="display-result-number">${{this.newTotalEquity}}</span></div>
    
                    </div>
                </div>
    
            </form>
    
            <br><br>
            <div class="adjusted display-result">
    
                <div class="row final-display">
                    <div class="col-5 final-display-text">total stocks</div>
                    <div class="col final-display-number">{{this.adjustedTotalStocks}} </div>
                </div>
    
                <div class="row final-display">
                    <div class="col-5 final-display-text">total investment</div>
                    <div class="col final-display-number">${{this.adjustedTotalInvestment}} </div>
                </div>
    
                <div class="row final-display">
                    <div class="col-5 final-display-text">average price</div>
                    <div class="col final-display-number">${{this.adjustedAvgPrice}} </div>
                </div>

            </div>

        </div>
      </div>


    <mat-dialog-actions>
   
   

        <button mat-dialog-close
        type="button" class="btn btn-outline-warning"> 
            <span class="material-icons-outlined">
                < back
            </span>
        </button>
         
            
    </mat-dialog-actions>


</div> -->
<h2 mat-dialog-title align="center" style="background-color: antiquewhite;">
    Buy calc for <span style="color: green;"> {{ticker}} </span>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="card-holder text-center dialogBox">
    
    
        <form [formGroup]="newBuyDialogForm" (change)="handleChange()">

            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>price per share</mat-label>
                        <input 
                        matInput required 
                        formControlName="cpps" 
                        type="number"
                        >
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <mat-label>number of shares</mat-label>
                        <input 
                        matInput required 
                        formControlName="cnos" 
                        type="number">
                    </mat-form-field>
                </div>
            </div>

            <div class="row display-result-dialog">
                <div class="col">

                    <div class="col">
                        <span class="display-result-text">
                            current total investment
                        </span>
                    </div>
                    <div class="col">
                        <span class="display-result-number">
                            ${{this.currTotalEquity}}
                        </span>
                    </div>
                    
                    
                </div>
            </div>

            <br><br>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>new price per share</mat-label>
                        <input 
                        matInput required 
                        formControlName="npps" 
                        type="number">
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <mat-label>new number of shares</mat-label>
                        <input 
                        matInput required 
                        formControlName="nnos" 
                        type="number">
                    </mat-form-field>
                </div>
            </div>

            <div class="row display-result-dialog">
                <div class="col">

                    <div class="col"><span class="display-result-text">new total investment</span></div>
                    <div class="col"><span class="display-result-number">${{this.newTotalEquity}}</span></div>

                </div>
            </div>

        </form>

        <br>
        <div class="adjusted display-result">

            <div class="row final-display">
                <div class="col-5 final-display-text">total stocks</div>
                <div class="col final-display-number">{{this.adjustedTotalStocks}} </div>
            </div>

            <div class="row final-display">
                <div class="col-5 final-display-text">total investment</div>
                <div class="col final-display-number">${{this.adjustedTotalInvestment}} </div>
            </div>

            <div class="row final-display">
                <div class="col-5 final-display-text">average price</div>
                <div class="col final-display-number">${{this.adjustedAvgPrice}} </div>
            </div>

        </div>

<!-- </mat-dialog-content> -->

<mat-dialog-actions align="center">

    <button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
    </button>
     
</mat-dialog-actions>



