import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-profit-loss-dialog',
  templateUrl: './profit-loss-dialog.component.html',
  styleUrls: ['./profit-loss-dialog.component.css']
})
export class ProfitLossDialogComponent implements OnInit {

  profitLossDialogForm : FormGroup;
  cTotalInvestment : number = 0;
  newInvestment : number = 0;
  diff : number = 0;
  changePct : number = 0;
  profit : boolean = true;

  ticker : string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data : Ticker,
    private formBuilder: FormBuilder,
    private trading : TradingHelperService
  ) { }

  ngOnInit(): void {
    this.ticker = this.data.ticker;
    this.profitLossDialogForm = this.formBuilder.group({
      ca : [{value : this.data.price, disabled : true}],
      cns : [{value : this.data.qty, disabled : true}],
      np : ['',[Validators.required, Validators.min(0)]],
    })
  }

  handleChange(){
    const data = this.trading.profitLossCalc(
      this.currAverage.value,
      this.currNumOfStocks.value,
      this.newPrice.value);
      
    this.cTotalInvestment = data.cTotalInvestment;
    this.newInvestment = data.newInvestment;
    this.diff = data.diff;
    this.changePct = data.changePct;
    this.profit = this.diff < 0 ? false : true;
  }

  //getters
  get currAverage(){return this.profitLossDialogForm.get('ca')}
  get currNumOfStocks(){return this.profitLossDialogForm.get('cns')}
  get newPrice(){return this.profitLossDialogForm.get('np')}

}
