import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.css']
})
export class BuyDialogComponent implements OnInit {

  newBuyDialogForm : FormGroup;
  currTotalEquity : number = 0;
  newTotalEquity : number = 0;
  adjustedTotalStocks : number = 0;
  adjustedTotalInvestment : number = 0;
  adjustedAvgPrice : number = 0;

  ticker : string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data : Ticker,
    private formBuilder: FormBuilder,
    private trading : TradingHelperService
  ) { }

  ngOnInit(): void {
    this.ticker = this.data.ticker;
    this.newBuyDialogForm = this.formBuilder.group({
      cpps : [{value: this.data.price, disabled: true}],
      cnos : [{value: this.data.qty, disabled: true}],
      npps : ['',[Validators.required, Validators.min(0)]],
      nnos : ['',[Validators.required, Validators.min(0)]],
    })
  }


  handleChange(){
    
    const data = this.trading.newStockPricingCalc(
      this.currPricePerShare.value,
      this.currNumOfShares.value,
      this.newPricePerShare.value,
      this.newNumOfShares.value);

    this.currTotalEquity = data.currTotalEquity;
    this.newTotalEquity = data.newTotal;
    this.adjustedTotalStocks = data.adjustedTotalStocks;
    this.adjustedTotalInvestment = data.adjustedTotalInvestment;
    this.adjustedAvgPrice = data.adjustedAvgPrice;
  }

  //getters
  get currPricePerShare(){return this.newBuyDialogForm.get('cpps')}
  get currNumOfShares(){return this.newBuyDialogForm.get('cnos')}
  get newPricePerShare(){return this.newBuyDialogForm.get('npps')}
  get newNumOfShares(){return this.newBuyDialogForm.get('nnos')}

}
