import { Injectable } from '@angular/core';
import { Router} from '@angular/router';

import { firebase } from '@firebase/app';
import '@firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';

import { Observable, of} from 'rxjs';
import { switchMap, first } from 'rxjs/operators'; 
import { User } from 'src/app/models/user.model'


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;


  constructor(
    private afAuth: AngularFireAuth,
    private afs : AngularFirestore,
    private router : Router
  ) { 
    this.user$ = this.afAuth.authState.pipe(
      switchMap( user => {
        if (user){
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else{
          return of(null);
        }
      }) 
    )
  }

  async googleSignIn(){
    // TODO:
    localStorage.setItem("read","0");
    localStorage.setItem("write","0");
    localStorage.setItem("delete","0");

    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }


  async signOut(){

    // TODO:
    localStorage.setItem("read","0");
    localStorage.setItem("write","0");
    localStorage.setItem("delete","0");
    
    await this.afAuth.signOut();
    return this.router.navigate['/'];
  }

  private updateUserData({uid, email, displayName, photoURL} : User){
    // sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`);

    const data = {
      uid,
      email,
      displayName,
      photoURL
    };

    return userRef.set(data, {merge: true});
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(first());
  }



}
