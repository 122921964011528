import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header-profile-menu',
  templateUrl: './header-profile-menu.component.html',
  styleUrls: ['./header-profile-menu.component.css']
})
export class HeaderProfileMenuComponent implements OnInit {

  constructor(
    public auth : AuthService
  ) { }

  ngOnInit(): void {
  }

}
