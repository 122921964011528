import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TradingHelperService {

  constructor() { }

  newStockPricingCalc(cAvg:number, cNStocks:number, nAvg:number, nNStocks:number)
  {
    const currTotalEquity:number = this.fTwoDecimal(cAvg * cNStocks);
    const nTotalInvestment:number = this.fTwoDecimal(nAvg * nNStocks);
    const adjustedNStocks:number = cNStocks + nNStocks;
    const adjustedTotalInvestment:number = this.fTwoDecimal(currTotalEquity+nTotalInvestment);
    const adjustedAvgPrice:number = this.fTwoDecimal(adjustedTotalInvestment/adjustedNStocks);

    return {
        "currTotalEquity" : currTotalEquity,
        "newTotal" : nTotalInvestment,
        "adjustedTotalStocks" : adjustedNStocks,
        "adjustedTotalInvestment" : adjustedTotalInvestment,
        "adjustedAvgPrice" : adjustedAvgPrice
      }
  }


  profitLossCalc(cAvg:number, cNStocks:number, newPrice:number)
  {
      const cTotalInvestment:number = this.fTwoDecimal(cAvg*cNStocks);
      const newInvestment:number = this.fTwoDecimal(newPrice*cNStocks);
      const diff = this.fTwoDecimal(newInvestment - cTotalInvestment);
      const changePct = this.fTwoDecimal(((newInvestment/cTotalInvestment) - 1) * 100);
      
      return {
        "cTotalInvestment" : cTotalInvestment,
        "newInvestment" : newInvestment,
        "diff" : diff,
        "changePct" : changePct
      }
  }

  breakEvenSell(cAvg:number, cNStocks:number, sharesToKeep:number)
  {
      const totalSpent = this.fTwoDecimal(cAvg * cNStocks);
      const sharesToSell = this.fTwoDecimal(cNStocks-sharesToKeep);
      const avgPricePerShareToSell = this.fTwoDecimal(totalSpent/sharesToSell);

      return {
        "totalSpent" : totalSpent,
        "sharesToSell" : sharesToSell,
        "sellPrice" : avgPricePerShareToSell,
        "totalEquityToSell" : this.fTwoDecimal(sharesToSell*avgPricePerShareToSell),
        "remainingSharesWorthAtSellPrice" : this.fTwoDecimal(sharesToKeep*avgPricePerShareToSell)
      }
  }

  riskCalculator(overallPortfolio:number, nShares:number, avgPricePerShare:number,riskTolerancePct:number
  )
  {
      riskTolerancePct = 1 - (riskTolerancePct/100);
      const newOverallPortfolio = overallPortfolio * riskTolerancePct;
      const selectedShareWorth = nShares * avgPricePerShare;
      const portfolioWOselectedShare = overallPortfolio - selectedShareWorth;

      const equityToBeSold = newOverallPortfolio - portfolioWOselectedShare;
      const avgPricePerShareTobeSold = equityToBeSold/nShares;
      
      return{
        "equityToBeSold" : this.fTwoDecimal(equityToBeSold),
        "avgPricePerShareTobeSold" : this.fTwoDecimal(avgPricePerShareTobeSold)
      }
  }
  
  
  // helper
  fTwoDecimal(n:number)
  {
    return parseFloat(Number(n).toFixed(2));
  }

}
