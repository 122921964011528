<!-- <div class="card-holder text-center">
    <div class="card">
        <div class="card-header">
            Profit/Loss calc for <span style="color: green;"> {{ticker}} </span>
        </div>

        <div class="card-body">
            <form [formGroup]="profitLossDialogForm" (change)="handleChange()">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>current average per share</mat-label>
                            <input 
                            matInput required 
                            formControlName="ca" 
                            type="number">
                        </mat-form-field>
                    </div>
    
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <mat-label>current number of stocks</mat-label>
                            <input 
                            matInput required 
                            formControlName="cns" 
                            type="number">
                        </mat-form-field>
                    </div>
    
                    <div class="col">
                        <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>new price per share</mat-label>
                            <input 
                            matInput required 
                            formControlName="np" 
                            type="number">
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <br><br>
            <div class="adjusted display-result">

                <div class="row final-display">
                    <div class="col-5 final-display-text">total investment</div>
                    <div class="col final-display-number">{{this.cTotalInvestment}} </div>
                </div>
    
                <div class="row final-display">
                    <div class="col-5 final-display-text">market value</div>
                    <div class="col final-display-number">${{this.newInvestment}} </div>
                </div>

                <div *ngIf = "profit; else loss">
                    <div class="row final-display">
                        <div class="col-5 final-display-text">profit</div>
                        <div class="col final-display-number">
                            <span class = "amount profit">
                                ${{this.diff}}
                            </span>
                            <span *ngIf = "changePct > 0">
                                <small>({{this.changePct}}%)</small>
                            </span>
                        </div>
                    </div>
                </div>

                <ng-template #loss>
                    <div class="row final-display">
                        <div class="col-5 final-display-text">loss</div>
                        <div class="col final-display-number">
                            <span class = "amount loss">
                                ${{this.diff}}
                            </span>
                            <span *ngIf = "changePct < 0">
                                <small>({{this.changePct}}%)</small>
                            </span>
                        </div>
                    </div>
                </ng-template>

            </div>


            
        </div>
    </div>
</div>


<br>
<button mat-dialog-close
    type="button" class="btn btn-outline-warning"> 
        <span class="material-icons-outlined">
            < back
        </span>
</button> -->
<h2 mat-dialog-title align="center" style="background-color: antiquewhite;">
    Profit/Loss calc for <span style="color: green;"> {{ticker}} </span>
</h2>


<mat-dialog-content>

                <form [formGroup]="profitLossDialogForm" (change)="handleChange()">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                                <span matPrefix>$ &nbsp;</span>
                                <mat-label>average per share</mat-label>
                                <input 
                                matInput required 
                                formControlName="ca" 
                                type="number">
                            </mat-form-field>
                        </div>
        
                        <div class="col-6">
                            <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                                <mat-label>number of stocks</mat-label>
                                <input 
                                matInput required 
                                formControlName="cns" 
                                type="number">
                            </mat-form-field>
                        </div>
        
                        <div class="col">
                            <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                                <span matPrefix>$ &nbsp;</span>
                                <mat-label>new price per share</mat-label>
                                <input 
                                matInput required 
                                formControlName="np" 
                                type="number">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
    
                <br>
                <div class="adjusted display-result">
    
                    <div class="row final-display">
                        <div class="col-5 final-display-text">total investment</div>
                        <div class="col final-display-number">${{this.cTotalInvestment}} </div>
                    </div>
        
                    <div class="row final-display">
                        <div class="col-5 final-display-text">market value</div>
                        <div class="col final-display-number">${{this.newInvestment}} </div>
                    </div>
    
                    <div *ngIf = "profit; else loss">
                        <div class="row final-display">
                            <div class="col-5 final-display-text">profit</div>
                            <div class="col final-display-number">
                                <span class = "amount profit">
                                    ${{this.diff}}
                                </span>
                                <span *ngIf = "changePct > 0">
                                    <small>({{this.changePct}}%)</small>
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <ng-template #loss>
                        <div class="row final-display">
                            <div class="col-5 final-display-text">loss</div>
                            <div class="col final-display-number">
                                <span class = "amount loss">
                                    ${{this.diff}}
                                </span>
                                <span *ngIf = "changePct < 0">
                                    <small>({{this.changePct}}%)</small>
                                </span>
                            </div>
                        </div>
                    </ng-template>
    
                </div>
    
    



</mat-dialog-content>


<mat-dialog-actions align="center">
    <button mat-dialog-close
        type="button" class="btn btn-outline-warning"> 
            <span class="material-icons-outlined">
                < back
            </span>
    </button>
</mat-dialog-actions>