import { Component, OnInit, Inject } from '@angular/core';
import { TickerService } from 'src/app/services/ticker/ticker.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticker } from 'src/app/models/ticker.model';


@Component({
  selector: 'app-delete-ticker-dialog',
  templateUrl: './delete-ticker-dialog.component.html',
  styleUrls: ['./delete-ticker-dialog.component.css']
})
export class DeleteTickerDialogComponent implements OnInit {

  constructor(
    private tickerService : TickerService,
    @Inject(MAT_DIALOG_DATA) public data : Ticker,

  ) { }

  ngOnInit(): void {
  }

  handleDeleteTicker(){
    this.tickerService.deleteTicker(this.data.tickerID);
  }

}
