import { Component, OnInit } from '@angular/core';
import { TradingHelperService } from 'src/app/services/trading-helper/trading-helper.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.css']
})
export class ProfitLossComponent implements OnInit {


  profitLossForm : FormGroup;
  cTotalInvestment : number = 0;
  newInvestment : number = 0;
  diff : number = 0;
  changePct : number = 0;
  profit : boolean = true;


  constructor(
    private trading : TradingHelperService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.profitLossForm = this.formBuilder.group({
      ca : ['',[Validators.required, Validators.min(0)]],
      cns : ['',[Validators.required, Validators.min(0)]],
      np : ['',[Validators.required, Validators.min(0)]],
    })
  }

  handleChange(){
    const data = this.trading.profitLossCalc(
      this.currAverage.value,
      this.currNumOfStocks.value,
      this.newPrice.value);
      
    this.cTotalInvestment = data.cTotalInvestment;
    this.newInvestment = data.newInvestment;
    this.diff = data.diff;
    this.changePct = data.changePct;
    this.profit = this.diff < 0 ? false : true;
  }

  //getters
  get currAverage(){return this.profitLossForm.get('ca')}
  get currNumOfStocks(){return this.profitLossForm.get('cns')}
  get newPrice(){return this.profitLossForm.get('np')}
}
