<div *ngIf="auth.user$ | async as user; else login">

    <div class="text-center" style="background-color: bisque; margin-bottom: 1rem;">
        <span style="font-size: larger; font-weight: bolder;">Saved Tickers</span>

        <small>
            <button mat-mini-fab class="float-end add-fab-btn" aria-label="add new" (click)="openAddDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </small>
    </div>

    <div *ngIf="tickers.length === 0; else tickertList">        
        You haven't added any tickers yet! <br>
        Press the + button to get started. 
      </div>
    
      <ng-template #tickertList>

        <mat-form-field class="search-field">
            <mat-label>Search Ticker Symbol</mat-label>
            <input matInput type="text" [(ngModel)]="query">
            <button mat-button *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="query=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>


        <table class="table table-bordered">
            <thead>
                <tr>
                  <th scope="col-4">Symbol</th>
                  <th scope="col-2">Avg. Cost</th>
                  <th scope="col-2">Shares</th>
                  <th scope="col-4">Calc</th>
                </tr>
            </thead>

            <tbody *ngFor="let n of tickers | search:query">
                
                <tr>
                    <td scope="col-4"  (click)="openUpdateDialog(n)">
                        <a> {{n.ticker}} </a>
                    </td>
                    <td scope="col-2">{{n.price}}</td>
                    <td scope="col-2">{{n.qty}}</td>
                    <td scope="col-4" (click)="openCaclOptionsDialog(n)">
            
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator" viewBox="0 0 16 16">
                            <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                          </svg>
                </tr>
            </tbody>
        </table>

        <small style="font-size: xx-small;">
            hint: click on the symbol name to update or delete.
        </small>
        
      </ng-template>

      
</div>

<ng-template #login>
    <img src="https://media.tenor.co/images/8fdfd478da20a79a4323cf28a90aca36/raw"
    style="width: 100%; max-width: 400px;">
    <br>
    hey! you need to sign in to save some tickers!! <br> <br>
    <button class="button" (click)="auth.googleSignIn()">
        login with google
    </button>
</ng-template>