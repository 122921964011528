import { NgModule } from '@angular/core';
import { AuthGuard } from 'src/app/services/auth/auth.guard';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '../../components/home/home.component';
import { ProfileComponent } from '../../components/account/profile/profile.component';
import { AccountSettingsComponent } from '../../components/account/account-settings/account-settings.component';
import { BuyComponent } from '../../components/calc/buy/buy.component';
import { ProfitLossComponent } from '../../components/calc/profit-loss/profit-loss.component';
import { TickersComponent } from '../../components/account/tickers/tickers.component';
import { BreakEvenSellComponent } from '../../components/calc/break-even-sell/break-even-sell.component';
import { RiskCalcComponent } from '../../components/calc/risk-calc/risk-calc.component';



const routes : Routes = [
  {path: '', component: HomeComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'buy', component: BuyComponent},
  {path: 'profit-loss', component: ProfitLossComponent},
  {path: 'tickers', component: TickersComponent},
  {path: 'break-even-sell', component: BreakEvenSellComponent},
  {path: 'risk-calc', component: RiskCalcComponent},
  {path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard]}
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
