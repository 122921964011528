<div class="text-center">
    <h3 mat-dialog-title style="background-color: tomato;">
        delete <strong>{{data.ticker}}</strong>
    </h3>
    
</div>

<mat-dialog-content>
    <div class="text-center">
        <img src="https://media.giphy.com/media/SqH0Lf0oTt13RN98Pa/giphy.gif" alt="" style="max-width: 100%;">
        

    <br>
    <br>
    Are your sure? 
    </div>
</mat-dialog-content>


<mat-dialog-actions>

    <div class="row">
        <div class="col-6">
            <button mat-button mat-dialog-close> cancel
                <mat-icon>close</mat-icon>
            </button>
        </div>
    
        <div class="col-6">
            <button mat-button mat-dialog-close (click)="handleDeleteTicker()" class="btn-delete"> delete
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
        
</mat-dialog-actions>