<h2 mat-dialog-title align="center" style="background-color: antiquewhite;">
    Calc options for <span style="color: green;"> {{ticker}} </span>
</h2>

<mat-dialog-content align="center"> 
    
    
    <div class="btn-group-vertical">
        <button type="button" class="btn btn-outline-success" (click)="openBuyCalcDialog()">buy</button> 
        <button type="button" class="btn btn-outline-success" (click)="openProfitLossDialog()">profit/loss</button> 
        <button type="button" class="btn btn-outline-success" (click)="openBreakevenSellDialog()">breakeven sell</button> 
        <button type="button" class="btn btn-outline-success" (click)="openRiskCalcDialog()">risk tolerance</button> 
    
    </div>
    

</mat-dialog-content>


<br>

<mat-dialog-actions align="center">

    <button mat-dialog-close
    type="button" class="btn btn-outline-danger"> 
        cancel
    </button>
        
        
</mat-dialog-actions>
