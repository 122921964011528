<div class="card-holder text-center">
    

<div class="card">
    <div class="card-header">
      Buy calc
    </div>
    <div class="card-body">

        <form [formGroup]="newBuyForm" (change)="handleChange()">

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>price per share</mat-label>
                        <input 
                        matInput required 
                        formControlName="cpps" 
                        type="number">
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <mat-label>number of shares</mat-label>
                        <input 
                        matInput required 
                        formControlName="cnos" 
                        type="number">
                    </mat-form-field>
                </div>
            </div>

            <div class="row display-result">
                <div class="col">

                    <div class="col">
                        <span class="display-result-text">
                            current total investment
                        </span>
                    </div>
                    <div class="col">
                        <span class="display-result-number">
                            ${{this.currTotalEquity}}
                        </span>
                    </div>
                    
                    
                </div>
            </div>

            <br><br>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>new price per share</mat-label>
                        <input 
                        matInput required 
                        formControlName="npps" 
                        type="number">
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline"  class="input-field" hideRequiredMarker>
                        <mat-label>new number of shares</mat-label>
                        <input 
                        matInput required 
                        formControlName="nnos" 
                        type="number">
                    </mat-form-field>
                </div>
            </div>

            <div class="row display-result">
                <div class="col">

                    <div class="col"><span class="display-result-text">new total investment</span></div>
                    <div class="col"><span class="display-result-number">${{this.newTotalEquity}}</span></div>

                </div>
            </div>

        </form>

        <br><br>
        <div class="adjusted display-result">

            <div class="row final-display">
                <div class="col-5 final-display-text">total stocks</div>
                <div class="col final-display-number">{{this.adjustedTotalStocks}} </div>
            </div>

            <div class="row final-display">
                <div class="col-5 final-display-text">total investment</div>
                <div class="col final-display-number">${{this.adjustedTotalInvestment}} </div>
            </div>

            <div class="row final-display">
                <div class="col-5 final-display-text">average price</div>
                <div class="col final-display-number">${{this.adjustedAvgPrice}} </div>
            </div>

            <!-- adjustedTotalStocks : {{this.adjustedTotalStocks}} <br>
            adjustedTotalInvestment : ${{this.adjustedTotalInvestment}} <br>
            adjustedAvgPrice : ${{this.adjustedAvgPrice}} -->
        </div>



      <!-- <h5 class="card-title">Special title treatment</h5>
      <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
      <a href="#" class="btn btn-primary">Go somewhere</a> -->

    </div>
  </div>
</div>