import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Ticker } from 'src/app/models/ticker.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TickerService } from 'src/app/services/ticker/ticker.service';
import { MatDialog } from '@angular/material/dialog';
import { AddTickerDialogComponent } from '../../dialogs/add-ticker-dialog/add-ticker-dialog.component';
import { UpdateTickerDialogComponent } from '../../dialogs/update-ticker-dialog/update-ticker-dialog.component';
import { CalcOptionsDialogComponent } from '../../dialogs/calc-options-dialog/calc-options-dialog.component';

@Component({
  selector: 'app-tickers',
  templateUrl: './tickers.component.html',
  styleUrls: ['./tickers.component.css']
})
export class TickersComponent implements OnInit {

  tickers : Ticker[] = [];
  query : string = '';

  // displayedColumns: string[] = ['ticker', 'index', 'qty', 'price', 'options'];


  constructor(
    public auth : AuthService,
    private tickerService : TickerService,
    public dialog : MatDialog
  ) { }

  ngOnInit(): void {
    
    this.auth.isLoggedIn().pipe(
      tap(user => {
        if(user)
        {
          this.loadTickers(user.uid);
        } else{
          console.log("meow");
        }
      })
    ).subscribe()
  }

  loadTickers(uid:string){
    this.tickerService.getTickers(uid).subscribe((result : Ticker[]) =>{
      this.tickers = result;
      // console.log(result);
    })
  }

  openAddDialog(){
    this.dialog.open(AddTickerDialogComponent);
  }

  openUpdateDialog(n){
    
    this.dialog.open(UpdateTickerDialogComponent, {data : {
      tickerID : n.tickerID,
      ticker : n.ticker,
      price : n.price,
      qty : n.qty
    }});
  }

  openCaclOptionsDialog(n){
    this.dialog.open(CalcOptionsDialogComponent, {data : {
      tickerID : n.tickerID,
      ticker : n.ticker,
      price : n.price,
      qty : n.qty
    }});

  }

}
